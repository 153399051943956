<template>
  <div id="materials" class="d-flex flex-column">
    <tslc-table :heads="headers" :items="materials" @sort="sort">
      <template v-slot:level="{item}">
        {{ item.level ? item.level.name: '-' }}
      </template>
      <template v-slot:actions="{item}">
        <template v-if="myTeacherUser.role === 'admin'">
          <v-btn text color="#243674" class="tslc-table__action"
                 :to="{name: 'EditMaterial', params: {id: item.id}}"
          >
            Editar
          </v-btn>
          <v-btn text color="#D94343" class="tslc-table__action" @click="doDeleteMaterial(item)">
            Eliminar
          </v-btn>
        </template>
        <v-btn v-if="myTeacherUser.role === 'teacher'" color="#243674" elevation="0"
               class="tslc-table__action white--text mx-2" @click="requestMaterial(item)"
        >
          Solicitar material
        </v-btn>
      </template>
    </tslc-table>
    <infinite-loading ref="materialsinfinite" force-use-infinite-wrapper="#materials" @infinite="infiniteHandler">
      <template v-slot:no-more>
        <div />
      </template>
      <template v-slot:no-results>
        <div />
      </template>
    </infinite-loading>
  </div>
</template>

<script>
import tslcTable from '@/components/TslcTable'
import { mapActions, mapState } from 'vuex'
import materialService from '@/services/materialService'

export default {
  name: 'MaterialesTable',
  components: {
    tslcTable
  },
  data () {
    return {
      showTeachingAssistanceModal: false,
      selectedMaterial: null,
      sortBy: 'order',
      orientation: 'desc',
      isVisible: false
    }
  },
  computed: {
    ...mapState('auth', ['myTeacherUser']),
    ...mapState('materials', ['materials', 'existNextMaterials']),
    headers () {
      if (this.myTeacherUser.role === 'teacher') {
        return [
          {
            name: 'Nombre',
            value: 'name',
            sortBy: 'name',
            width: '50%'
          },
          {
            name: 'Orden',
            value: 'order',
            width: '5%'
          },
          {
            name: 'Nivel sugerido',
            value: 'level',
            sortBy: 'level.order',
            width: '10%'
          },
          {
            name: '',
            value: 'actions',
            width: '40%'
          }
        ]
      } else {
        return [
          {
            name: 'Nombre',
            value: 'name',
            sortBy: 'name',
            width: '45%'
          },
          {
            name: 'Orden',
            value: 'order',
            sortBy: 'order',
            width: '5%'
          },
          {
            name: 'Nivel sugerido',
            value: 'level',
            sortBy: 'level.order',
            width: '10%'
          },
          {
            name: '',
            value: 'actions',
            width: '40%'
          }
        ]
      }
    }
  },
  mounted () {
    this.resetInfiniteLoading()
  },
  methods: {
    ...mapActions('materials', ['setEditedMaterial', 'getMaterials', 'deleteMaterial', 'resetMaterials']),
    async infiniteHandler ($state) {
      await this.getMaterials({ sortBy: this.sortBy, orientation: this.orientation })
      this.existNextMaterials ? $state.loaded() : $state.complete()
    },
    async doDeleteMaterial (item) {
      const message = `¿Está seguro de que desea eliminar el material ${item.name}?`
      const confirm = await this.$root.$confirm({
        message: message,
        confirmButtonText: 'Eliminar',
        type: 'alert'
      })
      if (confirm) {
        await this.deleteMaterial(item)
        this.$root.$confirm({
          message: `${item.name} ha sido eliminado de la lista de materiales`,
          showDismissButton: false
        })
      }
    },
    closeViewReportModal () {
      this.showTeachingAssistanceModal = false
      this.selectedMaterial = null
    },
    async requestMaterial (item) {
      const confirm = await this.$root.$confirm({
        message: `¿Está seguro que desea solicitar material ${item.name}?`,
        showDismissButton: false,
        type: 'alert'
      })

      if (confirm) {
        await materialService.createMaterialRequest(item, this.myTeacherUser)
        this.$root.$confirm({
          message: 'Solicitud enviada'
        })
      }
    },
    async resetInfiniteLoading () {
      await this.resetMaterials()
      await this.$refs.materialsinfinite.stateChanger.reset()
    },
    async sort ({ sortBy, orientation }) {
      this.sortBy = sortBy
      this.orientation = orientation
      await this.resetInfiniteLoading()
    }
  }
}
</script>

<style scoped lang="scss">
.class-chip {
  width: 136px;
  height: 42px;
  border-radius: 10px !important;
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px 0;
}

</style>
