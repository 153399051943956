<template>
  <v-simple-table style="width: 100%;" fixed-header max-height="70vh">
    <thead class="tslc-table">
      <slot name="head" :heads="heads" :showIndex="showIndex">
        <tr>
          <th v-if="showIndex">
            #
          </th>
          <th v-for="(th, i) in heads" :key="i" class="text-left" :style="{'width': th.width || 'auto'}">
            <slot :name="`head-${th.value}`" :item="th">
              <template v-if="th.sortBy">
                <div class="d-flex justify-center align-center" @click="sort(th.sortBy)">
                  {{ th.name }}
                  <v-icon v-if="sortBy === th.sortBy" small color="white" class="mx-1">
                    {{ desc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                  </v-icon>
                </div>
              </template>
              <template v-else>
                <div class="d-flex justify-center align-center">
                  {{ th.name }}
                </div>
              </template>
            </slot>
          </th>
        </tr>
      </slot>
    </thead>

    <tbody>
      <slot name="body">
        <tr v-for="(item, index) in items" :key="index">
          <td v-if="showIndex" class="tslc-table__item--light">
            {{ index + 1 }}
          </td>
          <td v-for="head in heads" :key="head.value" class="tslc-table__item">
            <slot :name="head.value" :item="item">
              {{ item[head.value] }}
            </slot>
          </td>
        </tr>
      </slot>
      <slot name="infinite" />
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: 'TslcTable',
  props: {
    items: Array,
    heads: Array,
    showIndex: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      desc: true,
      sortBy: ''
    }
  },
  methods: {
    sort (sortBy) {
      this.sortBy = sortBy
      this.desc = !this.desc
      this.$emit('sort', { sortBy, orientation: this.desc ? 'desc' : 'asc' })
    }
  }
}
</script>

<style scoped lang="scss">

.tslc-table {
  &__item {
    color: #404040;
    letter-spacing: -0.04em;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;

    &--light {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #67757C;
    }
  }
}

</style>
