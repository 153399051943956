<template>
  <v-container fluid>
    <v-main class="mx-auto">
      <div class="d-flex justify-space-between align-center">
        <h3 class="subheader__title mb-8">
          Material de estudios
        </h3>
        <form-button
          v-if="myTeacherUser.role === 'admin'"
          width="200px" height="50px" class="mb-8"
          :to="{name: 'AddMaterial'}"
        >
          Agregar material
        </form-button>
        <!--        <base-text-field placeholder="Buscar material" prepend-inner-icon="mdi-magnify" />-->
      </div>
      <div class="d-flex flex-column">
        <materials-table />
        <!--        <new-material-modal v-if="myTeacherUser.role === 'admin'" v-model="showNewMaterialModal" class="mx-auto" @close="closeNewMaterialModal" />-->
      </div>
    </v-main>
  </v-container>
</template>

<script>
import materialsTable from '@/components/material/MaterialsTable'
// import newMaterialModal from '@/components/modals/NewMaterialModal'
// import BaseTextField from '@/components/inputs/BaseTextField'
import { mapActions, mapState } from 'vuex'
import FormButton from '@/components/buttons/FormButton'

export default {
  name: 'Material',
  components: {
    materialsTable,
    FormButton
    // newMaterialModal
    // BaseTextField
  },
  data () {
    return {
      showNewMaterialModal: false
    }
  },
  computed: {
    ...mapState('auth', ['myTeacherUser']),
    ...mapState('materials', ['editedMaterial'])
  },
  watch: {
    editedMaterial (newValue) {
      if (newValue) {
        this.showNewMaterialModal = true
      }
    }
  },
  methods: {
    ...mapActions('materials', ['setEditedMaterial']),
    closeNewMaterialModal () {
      this.showNewMaterialModal = false
      if (this.editedMaterial) {
        this.setEditedMaterial(null)
      }
    }
  }

}
</script>

<style scoped>

</style>
